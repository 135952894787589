import enLocales from "./en.json";
import jpLocales from "./jp.json";

export const defaultNS = "translations";
export const resources = {
  en: { translations: enLocales },
  ja: { translations: jpLocales },
} as const;

export const defaultLocale = "ja";
