const enTemplate = `
<!DOCTYPE html>
<html>
<head>
    <style>
        html, body {
            margin: 0;
            padding: 0;
        }

        body {
            background-color: #F7F7FB;
        }

        .body {
            padding: 62px;
        }

        @media only screen and (max-width: 600px) {
            .header {
                height: 100px;
            }

            .body {
                padding: 30px;
            }
        }
    </style>
</head>
<body>
  <div style="width: 100%; height: 100%; background-color: #EBEEF2; padding: 80px 20px;">
<center>
    <table width="650px" border="0" cellpadding="0" cellspacing="0" bgcolor="#FFFFFF"
           style="border-radius: 16px; overflow: hidden; background-color: #FFFFFF;">
        <tr>
            <td class="header"
                style="height: 195px;">
                <img src="https://aironworks-gmailaddin-artifacts.s3.ap-northeast-1.amazonaws.com/header.png" width="600" alt="AironWorks"
                     style="display: block; width: 100%; height: auto;"/>
            </td>
        </tr>
        <tr>
            <td class="body" style="text-align: center;">
                <h1 style="font-size: 16px; font-weight: 600; color: #111940;">Hi, Security Operation Center Team!</h1>
                <p style="font-size: 14px; font-weight: 400; color: #111940;">We've received a report from one
                    of
                    your employees, {{employee_email}} , about a suspicious email they came across today.
                    The original email reported by the employee has been attached
                    for your analysis.</p>
                <h2 style="font-size: 14px; font-weight: 600; color: #111940; margin-top: 48px;">Here's a summary of the
                    employee email metadata:</h2>
                <table width="100%" border="0" cellpadding="0" cellspacing="0"
                       style="background-color: #F7F7FB; margin:0 auto 48px auto; border-collapse: collapse;">
                    <tr>
                        <th style="width: 165px; font-size: 12px; font-weight: 600; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                            Sender's email address
                        </th>
                        <td style="font-size: 14px; font-weight: 400; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                          {{sender_email}}
                        </td>
                    </tr>
                    <tr>
                        <th style="width: 165px; font-size: 12px; font-weight: 600; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                            Date and time received
                        </th>
                        <td style="font-size: 14px; font-weight: 400; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                          {{date}}
                        </td>
                    </tr>
                    <tr>
                        <th style="width: 165px; font-size: 12px; font-weight: 600; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                            Subject
                        </th>
                        <td style="font-size: 14px; font-weight: 400; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                          {{subject}}
                        </td>
                    </tr>
                    <tr>
                        <th style="width: 165px; font-size: 12px; font-weight: 600; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                            Attachment(s)
                        </th>
                        <td style="font-size: 14px; font-weight: 400; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                          {{attachments}}
                        </td>
                    </tr>
                        <tr>
                            <th style="width: 165px; font-size: 12px; font-weight: 600; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                                User message
                            </th>
                            <td style="font-size: 14px; font-weight: 400; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                              {{user_message}}
                            </td>
                        </tr>
                </table>
                {{#risk_level}}
                <h2 style="font-size: 14px; font-weight: 600; color: #111940; margin-top: 48px;">Our AI Assessment has
                    produced the following results:</h2>
                <table width="100%" border="0" cellpadding="0" cellspacing="0"
                       style="background-color: #F7F7FB; margin:0 auto 48px auto; border-collapse: collapse;">
                    <tr>
                        <th
                                style="width: 165px; font-size: 12px; font-weight: 600; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                            Email Security Level
                        </th>
                        <td style="font-size: 14px; font-weight: 400; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                            <!--                            A security level starts here-->
                            <!--                            #FA2148 - High-->
                            <!--                            #FA5521 - Moderate high -->
                            <!--                            #FFB11B - Moderate -->
                            <!--                            #2B9BB4 - Moderate low -->
                            <!--                            #2FCF96 - low -->

                                <div style="background-color: {{risk_level_color}}; border-radius: 4px; padding: 4px 8px; width: max-content;">
                                  <font color="#fff">{{risk_level_formatted}}</font>
                                </div>
                            <!--                            A security level ends here-->
                        </td>
                    </tr>
                    <tr>
                        <th valign="top"
                            style="width: 165px; font-size: 12px; font-weight: 600; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                            Security Level Description
                        </th>
                        <td style="font-size: 14px; font-weight: 400; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                          {{{description}}}
                        </td>
                    </tr>
                    {{#reasons}}
                    <tr>
                        <th valign="top"
                            style="width: 165px; font-size: 12px; font-weight: 600; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                            Security Reason {{index}}
                        </th>
                        <td style="font-size: 14px; font-weight: 400; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                            <table border="0" cellpadding="0" cellspacing="0">
                                <tr>
                                    <td style="padding-bottom: 12px;"><b>Title:</b> {{reason.title}} </td>
                                </tr>
                                <tr>
                                    <td><b>Description:</b> {{reason.description}}</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    {{/reasons}}
                </table>
                {{/risk_level}}
                <p style="font-size: 14px; font-weight: 400; color: #111940;">We kindly request that you analyze the
                    attached email and its content to determine the nature of the threat and provide guidance on how to
                    proceed.
                    Additionally, please take any necessary actions to mitigate the risk and prevent further incidents.
                </p>
                <p style="font-size: 14px; font-weight: 400; color: #111940;"> Thank you for your prompt attention to
                    this
                    matter. <br/>
                    Stay alert, stay secure!
                </p>
                <h2 style="font-size: 14px; font-weight: 600; color: #111940;"> AironWorks Security Team</h2>
            </td>
        </tr>
    </table>
</center>
  </div>
</body>
</html>
`;

const jaTemplate = `
<!DOCTYPE html>
<html>
<head>
    <style>
        html, body {
            margin: 0;
            padding: 0;
        }

        body {
            background-color: #F7F7FB;
        }

        .body {
            padding: 62px;
        }

        @media only screen and (max-width: 600px) {
            .header {
                height: 100px;
            }

            .body {
                padding: 30px;
            }
        }
    </style>
</head>
<body>
  <div style="width: 100%; height: 100%; background-color: #EBEEF2; padding: 80px 20px;">
<center>
    <table width="650px" border="0" cellpadding="0" cellspacing="0" bgcolor="#FFFFFF"
           style="border-radius: 16px; overflow: hidden;">
        <tr>
            <td class="header"
                style="height: 195px;">
                <img src="https://aironworks-gmailaddin-artifacts.s3.ap-northeast-1.amazonaws.com/header.png" width="600" alt="AironWorks"
                     style="display: block; width: 100%; height: auto;"/>
            </td>
        </tr>
        <tr>
            <td class="body" style="text-align: center;">
                <h1 style="font-size: 16px; font-weight: 600; color: #111940;">セキュリティ担当者様</h1>
                <p style="font-size: 14px; font-weight: 400; color: #111940;">従業員の {{employee_email}}, 様から、不審なメールについて報告がありました。
                    報告されたメールは添付のとおりです。
                </p>
                <h2 style="font-size: 14px; font-weight: 600; color: #111940; margin-top: 48px;">
                 以下は、報告されたメールのメタデータの要約になります：
                 </h2>
                <table width="100%" border="0" cellpadding="0" cellspacing="0"
                       style="background-color: #F7F7FB; margin:0 auto 48px auto; border-collapse: collapse;">
                    <tr>
                        <th style="width: 165px; font-size: 12px; font-weight: 600; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                            送信者のメールアドレス：
                        </th>
                        <td style="font-size: 14px; font-weight: 400; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                          {{sender_email}}
                        </td>
                    </tr>
                    <tr>
                        <th style="width: 165px; font-size: 12px; font-weight: 600; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                            受信日時：
                        </th>
                        <td style="font-size: 14px; font-weight: 400; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                          {{date}}
                        </td>
                    </tr>
                    <tr>
                        <th style="width: 165px; font-size: 12px; font-weight: 600; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                            件名：
                        </th>
                        <td style="font-size: 14px; font-weight: 400; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                          {{subject}}
                        </td>
                    </tr>
                    <tr>
                        <th style="width: 165px; font-size: 12px; font-weight: 600; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                            添付ファイル：
                        </th>
                        <td style="font-size: 14px; font-weight: 400; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                          {{attachments}}
                        </td>
                    </tr>
                        <tr>
                            <th style="width: 165px; font-size: 12px; font-weight: 600; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                                ユーザーからのメッセージ
                            </th>
                            <td style="font-size: 14px; font-weight: 400; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                              {{user_message}}
                            </td>
                        </tr>
                </table>
                {{#risk_level}}
                <h2 style="font-size: 14px; font-weight: 600; color: #111940; margin-top: 48px;">
                  AIによるアセスメントの結果は以下のとおりです：
                </h2>
                <table width="100%" border="0" cellpadding="0" cellspacing="0"
                       style="background-color: #F7F7FB; margin:0 auto 48px auto; border-collapse: collapse;">
                    <tr>
                        <th
                                style="width: 165px; font-size: 12px; font-weight: 600; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                            電子メールの危険度レベル
                        </th>
                        <td style="font-size: 14px; font-weight: 400; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                            <!--                            A security level starts here-->
                            <!--                            #FA2148 - High-->
                            <!--                            #FA5521 - Moderate high -->
                            <!--                            #FFB11B - Moderate -->
                            <!--                            #2B9BB4 - Moderate low -->
                            <!--                            #2FCF96 - low -->

                                <div style="background-color: {{risk_level_color}}; border-radius: 4px; padding: 4px 8px; width: max-content;">
                                  <font color="#fff">{{risk_level_formatted}}</font>
                                </div>
                            <!--                            A security level ends here-->
                        </td>
                    </tr>
                    <tr>
                        <th valign="top"
                            style="width: 165px; font-size: 12px; font-weight: 600; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                            危険度レベルについて
                        </th>
                        <td style="font-size: 14px; font-weight: 400; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                          {{{description}}}
                        </td>
                    </tr>
                    {{#reasons}}
                    <tr>
                        <th valign="top"
                            style="width: 165px; font-size: 12px; font-weight: 600; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                            判定の理由 {{index}}
                        </th>
                        <td style="font-size: 14px; font-weight: 400; color: #111940; padding: 10px; text-align: start; border-color: #E1E1E1; border-width: 1px; border-style: solid;">
                            <table border="0" cellpadding="0" cellspacing="0">
                                <tr>
                                    <td style="padding-bottom: 12px;"><b>タイトル:</b> {{reason.title}} </td>
                                </tr>
                                <tr>
                                    <td><b>説明:</b> {{reason.description}}</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    {{/reasons}}
                </table>
                {{/risk_level}}
                <p style="font-size: 14px; font-weight: 400; color: #111940;">
                  添付された電子メールとその内容を分析の上、報告者に対して、どのように対処すべきか通知いただくことをお勧めいたします。
                </p>
              
                <h2 style="font-size: 14px; font-weight: 600; color: #111940;"> AironWorksセキュリティチーム</h2>
            </td>
        </tr>
    </table>
</center>
  </div>
</body>
</html>
`;

export const templates = {
  "en-US": enTemplate,
  ja: jaTemplate,
};
