import { defaultLocale } from "./constants.ts";

export function getCurrentLocale() {
  if (Office?.context?.displayLanguage) {
    return Office.context.displayLanguage;
  } else if (navigator.language) {
    return navigator.language;
  }
  return defaultLocale;
}

export function getShortenedLocale(locale: string) {
  return locale.split("-")[0];
}

const supportedLocales = ["en", "ja"];
export function getCurrentCachedLocale() {
  const locale = getCurrentLocale();
  const language = getShortenedLocale(locale);
  if (!supportedLocales.includes(language)) {
    return defaultLocale;
  }
  return language;
}

export function adaptLocaleToServerValue(locale: string) {
  switch (locale) {
    case "en":
      return "en";
    case "ja":
      return "jp";
    default:
      return "jp";
  }
}

export function getAdaptedCacheLocale() {
  const currentLocale = getCurrentCachedLocale();
  return adaptLocaleToServerValue(currentLocale);
}
