import { useQuery } from "react-query";
import { apiHandlers, getOutlookEmailMimeContent, sendLargeMail } from "../api";
import { getAdaptedCacheLocale } from "../locales";
import { useLoginContext } from "../api/loginContext";
import { useUserInfoQuery } from "./useUserInfoQuery";
import { useLocales } from ".";
import { templates } from "../templates/htmlBody";
import Mustache from "mustache";

const MAX_RETRY_TIME_SEC = 50;
const BASE_DELAY_IN_MS = 100;
const DELAY_GROWTH = 1.2;
const MAX_DELAY_IN_MS = 2000;

function camelCase(str: string) {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase());
}

const risk_level_color = {
  HIGH: '#FA2148',
  MODERATE_HIGH: '#FA5521',
  MODERATE: '#FFB11B',
  MODERATE_LOW: '#2B9BB4',
  LOW: '#2FCF96',
  BYPASS: '#2FCF96',
}

export function useAssessmentQuery() {
  const { accessToken } = useLoginContext();
  const { data } = useUserInfoQuery();
  const { translate, currentLanguage } = useLocales();
  return useQuery(
    ["assessment"],
    () => {
      return getOutlookEmailMimeContent(accessToken).then(async (content) => {
        if (data?.soc_email === undefined) {
          throw new Error("No email address found");
        }
        // expects a promise, so we should be able to use async
        let result = await apiHandlers.assessment.check(
          {
            language: getAdaptedCacheLocale(),
            mime_content: content,
          },
          { headers: { Authorization: `MSN ${accessToken}` } }
        );
        const requestId = result.data.id;
        let currentDelay = BASE_DELAY_IN_MS;
        let totalWaitTime = 0;
        while (
          result.data.status !== "DONE" &&
          result.data.status !== "ERROR"
        ) {
          await new Promise((resolve) => setTimeout(resolve, currentDelay));
          totalWaitTime += currentDelay / 1000;
          if (totalWaitTime > MAX_RETRY_TIME_SEC) {
            throw new Error("Assessment took too long to complete");
          }
          currentDelay = Math.min(MAX_DELAY_IN_MS, currentDelay * DELAY_GROWTH);
          result = await apiHandlers.assessment.check(
            {
              id: requestId,
            },
            { headers: { Authorization: `MSN ${accessToken}` } }
          );
        }
        const resultData = result.data;
        const riskLevel = resultData.assessment_result.assessment.level ?? '';
        const riskLevelCamel = camelCase(riskLevel);
        const riskTitle = translate(`assessment.riskLevels.${riskLevelCamel}.title` as any);
        const riskDescription = translate(`assessment.riskLevels.${riskLevelCamel}.description` as any).replace('<1>', '<b>').replace('</1>', '</b>');
        const title = translate('soc_email.subject', { level: riskTitle });
        const htmlBody = Mustache.render(templates[currentLanguage?.value ?? 'ja'], {
          employee_email: Office.context.mailbox.userProfile.emailAddress,
          sender_email: Office.context.mailbox.item?.sender.emailAddress,
          date: Office.context.mailbox.item?.dateTimeCreated,
          subject: Office.context.mailbox.item?.subject,
          attachments: Office.context.mailbox.item?.attachments ? 'Yes' : 'None',
          risk_level: riskLevel,
          risk_level_color: risk_level_color[riskLevel == '' ? 'LOW' : riskLevel],
          risk_level_formatted: riskTitle,
          description:  riskDescription,
          reasons: Array.from(resultData.assessment_result.assessment.details?.entries() ?? []).map(([key, value]) => ({index: key + 1, reason: value})),
        })
        await sendLargeMail(accessToken ?? '', title, htmlBody, data?.soc_email, content ?? '');
        return result.data.assessment_result;
      });
    },
    { enabled: !!accessToken }
  );
}
