import { useMutation } from "react-query";
import {
  apiHandlers,
  AssessmentRequestData,
  getOutlookEmailMimeContent,
  sendLargeMail,
} from "../api";
import { getAdaptedCacheLocale } from "../locales";
import { useLoginContext } from "../api/loginContext";
import { templates } from "../templates/htmlBody";
import { useLocales } from ".";
import { useUserInfoQuery } from "./useUserInfoQuery";
import Mustache from "mustache";

export function useReportMutation() {
  const { accessToken } = useLoginContext();
  const { translate, currentLanguage } = useLocales();
  const { data: userData } = useUserInfoQuery();
  return useMutation((data: Pick<AssessmentRequestData, "user_message">) =>
    getOutlookEmailMimeContent(accessToken).then(async (content) => {
      const result = await apiHandlers.assessment.check(
        {
          ...data,
          mime_content: content,
          language: getAdaptedCacheLocale(),
        },
        { headers: { Authorization: `MSN ${accessToken}` } }
      );
      const title = translate("soc_email.subject", { level: "" });
      const htmlBody = Mustache.render(
        templates[currentLanguage?.value ?? "en-US"],
        {
          employee_email: Office.context.mailbox.userProfile.emailAddress,
          sender_email: Office.context.mailbox.item?.sender.emailAddress,
          date: Office.context.mailbox.item?.dateTimeCreated,
          subject: Office.context.mailbox.item?.subject,
          attachments: Office.context.mailbox.item?.attachments
            ? "Yes"
            : "None",
          user_message: data.user_message,
        }
      );
      await sendLargeMail(
        accessToken ?? "",
        title,
        htmlBody,
        userData?.soc_email ?? "",
        content ?? ""
      );
      return result;
    })
  );
}
